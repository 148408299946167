import React, { useRef, useState } from "react";

import classes from "./Location.module.css";
import emailjs from "@emailjs/browser";

const Location = () => {
  const [islegal, setIsLegal] = useState(true);

  const phoneInputRef = useRef();
  let styles = `${classes.buttonApplyDynamic}`;

  if (!islegal) {
    styles = ` ${classes.disabled}`;
  } else {
    styles = `${classes.buttonApplyDynamic}`;
  }

  const handleChange = (e) => {
    e.preventDefault();
    const enteredNumber = phoneInputRef.current.value;
    
    if (enteredNumber.length !== 10) {
      setIsLegal(false);
    } else {
      setIsLegal(true);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rxhv2zl",
        "template_6hdbq0n",
        e.target,
        "dFyKbygy_pFJvcRDB"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className={`${classes.waveR} ${classes.spacerR}`}>
      <section className={classes.sectionStyle}>
        <div className={classes.formWrapper}>
          <div className={classes.wrapperH}>
            {" "}
            <h1> Get a Quote</h1>
          </div>
          <form className={classes.formAction} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Email Id" required />
            <input
              type="tel"
              name="number"
              placeholder="Your Mobile"
              required
              ref={phoneInputRef}
              onChange={handleChange}
            />
            <textarea name="message" placeholder="your Message" />

            <input className={styles} type="submit" />
          </form>
        </div>
        <div className={classes.wrapperDiv}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m24!1m12!1m3!1d384.9306740893984!2d85.30408661562569!3d23.38465830624974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m9!3e0!4m3!3m2!1d23.3844663!2d85.3042112!4m3!3m2!1d23.3844653!2d85.30421009999999!5e0!3m2!1sen!2sin!4v1656412854060!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Location;
