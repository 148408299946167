import style from './ourPartners.module.css'
import boi from '../utils/images/BOILogo.jpg'
import aryaBank from '../utils/images/AryaBank.jpg'
import mpb from '../utils/images/MPBank.png'
const OurPartners = () =>{
   const partners = [boi, aryaBank, mpb]

    return (
        <>
         
       
        <section className={style.OurPartnersSection}>

            
            {partners.map((p, id)=>{
                return (<div className={style.OurPartnersSectionDiv} key ={id}> <img src={p} key={id} alt= "images"/>
                 </div>)
            })}
    
            
        </section>
        </>
    )

}

export default OurPartners;