import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./HeadNavbar.module.css";
import MobileNav from "./MobileNav";
import TopNav from "./TopNav";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faUserGroup,
  faCog,
  faGraduationCap,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";
const HeadNavbar = () => {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 878;

  return (
    <>
      <TopNav />

      {isMobile ? (
        <MobileNav />
      ) : (
        <section className={classes.navContainerFinmap}>
          <nav className={classes.navFinmapSol}>
            <ul className={classes.navul}>
              <li className={classes.navli}>
                <NavLink className={classes.navLink} to="/">
                  {" "}
                  <FontAwesomeIcon icon={faHome} /> Home{" "}
                </NavLink>
              </li>
              <li className={classes.navli}>
                <NavLink className={classes.navLink} to="/About-us">
                  <FontAwesomeIcon icon={faUser} /> About us
                </NavLink>
              </li>
              <li className={classes.navli}>
                <NavLink to="our-team">
                  {" "}
                  <FontAwesomeIcon icon={faUserGroup} /> Our Team{" "}
                </NavLink>
              </li>
              <li className={classes.navli}>
                <NavLink to="/services">
                  <FontAwesomeIcon icon={faCog} /> Services{" "}
                </NavLink>
              </li>

              <li className={classes.navli}>
                <NavLink to="/careers">
                  {" "}
                  <FontAwesomeIcon icon={faGraduationCap} /> Careers{" "}
                </NavLink>
              </li>
            </ul>

            <ul className={classes.navul}>
              <li className={classes.navli}>
                <a href="/">
                  {" "}
                  <FontAwesomeIcon icon={faSignIn} /> Login{" "}
                </a>
              </li>
            </ul>
          </nav>
        </section>
      )}
    </>
  );
};

export default HeadNavbar;
