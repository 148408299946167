import React,{Suspense} from 'react'
import "./App.css";
import "./Map.css";
import Footer from "./components/Navbar/Footer";
import HeadNavbar from "./components/Navbar/HeadNavbar";
import Home from "./components/Pages/Home";
// import AboutUs from "./components/Pages/AboutUs";
import { Routes, Route } from "react-router-dom";
// import ServiceDisplay from "./components/Pages/ServicesDisplay";
// // import OurTeam from "./components/Pages/OurTeam";
// import Services from "./components/Pages/Services";
// import Careers from "./components/Pages/Careers";
// import Contact from "./components/Pages/Contact";
// import JobDescription from "./components/Pages/JobDescription";
// import PageNotFound from "./components/Pages/PageNotFound";

const AboutUs = React.lazy(()=> import('./components/Pages/AboutUs'))
const OurTeam = React.lazy(()=> import('./components/Pages/OurTeam'))
const Services = React.lazy(()=> import('./components/Pages/Services'))
const Careers = React.lazy(()=> import('./components/Pages/Careers'))
const Contact = React.lazy(()=> import('./components/Pages/Contact'))
const JobDescription = React.lazy(()=> import('./components/Pages/JobDescription'))
const PageNotFound = React.lazy(()=> import('./components/Pages/PageNotFound'))
const ServiceDisplay = React.lazy(()=> import('./components/Pages/ServicesDisplay'))

function App() {
  return (
    <>
      <HeadNavbar />
        <Suspense fallback={<div style={{display:'flex',justifyContent:'center', alignItems:'center', minHeight:'90vh'}}>Loading...</div>}>
      <Routes>
      
        <Route exact path="/" element={<Home />} ></Route>
        <Route exact path="/about-us" element={<AboutUs />}></Route>
        <Route path="/:id" element={<ServiceDisplay />}></Route>
        <Route exact path="/our-team" element={<OurTeam />}></Route>
        <Route exact path="/services" element={<Services />}></Route>
        <Route exact path="/careers" element={<Careers />}></Route>
        <Route  exact path="/contact-us" element={<Contact />}></Route>
        <Route exact path="/job-description/:id" element={<JobDescription />}></Route>
        <Route path = "*" element = {<PageNotFound/>}> </Route>

      </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
