import { NavLink } from "react-router-dom";
import classes from "./Career.module.css";

const Career = () => {
  return (
    <section className={classes.SectionCareers}>
      <div className={classes.wrapperDiv}>
        <div className={classes.CareersDiv}>
          <h1 className={classes.h1Careers}>Want to Work with us ? </h1>
          <NavLink className={classes.NavLinkCareers} to="/careers">
            {" "}
            Careers{" "}
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default Career;
