import classes from "../Services.module.css";

import { Link } from "react-router-dom";


const Cards = (props) => {
  let isDisabled = false;
  let styleS = `${classes.Services} + " "`;
  if(props.isDisabled === "True"){
    isDisabled= true;

    styleS = `${classes.Services} + ${classes.disabledA}`;
  }
  

  return (
    <div className={styleS}      >
          <div className={classes.iconBox}>
        <img src={props.image} alt={props.title}></img>
      </div>
      <div className={classes.iconBoxShadow}></div>
      <h5 className={classes.h5Services}>{props.title}</h5>

                

      <div className={classes.summary}>
        <p> {props.summary}</p>
      </div> 

{isDisabled ? <div className={classes.comingSoon}> <h1>Coming Soon</h1></div> :
<Link
  
        className={classes.NavLinkServices}
        to={{
          pathname: `/${props.title.replace(/ /g, "-").toLowerCase()}`,
        }}
      >
      
        Read More
      </Link>
    

}
    
    </div>
  );
};

export default Cards;
