import { useState } from "react";
import classes from "./MobileNav.module.css";
import {NavLink} from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faUserGroup, faCog,faGraduationCap, faSignIn } from "@fortawesome/free-solid-svg-icons";

const MobileNav = () => {
  const [mobileNavEnabled, setMobileNavEnabled] = useState(false);

  const NavBarOpen = () => {
    if(mobileNavEnabled){
        setMobileNavEnabled(false);
        document.querySelector('body').style.overflowY = 'auto';

    } else{
        setMobileNavEnabled(true);
        document.querySelector('body').style.overflowY = 'hidden';

    }

    
    
  };

  const handlePageNav = () =>{
    if(mobileNavEnabled){
      setMobileNavEnabled(false);
      document.querySelector('body').style.overflowY = 'auto';
    }
  }

  

  const MobileNavigation = () => {
    return (
      <>
      <button className={classes.CloseButton} onClick={NavBarOpen}> X </button>
      <div className={classes.ContentNavBarMobile}>
    
        <ul className={classes.navul}>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/" onClick={handlePageNav}>
              
            <FontAwesomeIcon icon={faHome} />  Home
            </NavLink>
          </li>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/About-us" onClick={handlePageNav}>
             
            <FontAwesomeIcon icon={faUser}/>  About us
            </NavLink>
          </li>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/our-team" onClick={handlePageNav}>
            <FontAwesomeIcon icon={faUserGroup}/> Our Team
            </NavLink>
          </li>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/services" onClick={handlePageNav}>
            <FontAwesomeIcon icon={faCog}/> Services
            </NavLink>
          </li>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/careers" onClick={handlePageNav}>
            <FontAwesomeIcon icon={faGraduationCap}/> Careers
            </NavLink>
          </li>
          <li className={classes.navli}>
            <NavLink class={classes.navLink} to="/About-us" onClick={handlePageNav}>
            <FontAwesomeIcon icon={faSignIn}/>  Login
            </NavLink>
          </li>
        </ul>
      </div>
      </>
    );
  };
  return (
    <nav className={classes.navContainerMobileFinmap}>
      <div className={classes.Logo} onClick={NavBarOpen}>
        <span className={classes.bar1}></span>
        <span className={classes.bar2}></span>
        <span className={classes.bar3}></span>
      </div>

      {mobileNavEnabled ? <MobileNavigation/> : " "}
    </nav>
  );
};

export default MobileNav;
