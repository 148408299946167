import classes from "./BelowSection.module.css";

import OurPartners from "./OurPartners";
const BelowSection = () => {
  return (
    
   <>
    <section className={classes.LowerSection}>
      <div className={classes.LowerSectionDiv}>

        <div className={classes.CenterLowerDiv}>
      <div className={classes.HeaderOurPartners}> <h1>Our Partners</h1></div>

        
            <OurPartners/>
      
        </div>
      </div>
    </section>
   </>
  
  );
};

export default BelowSection;
