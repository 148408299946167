import { NavLink } from "react-router-dom";
import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarker, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className={classes.footerDiv}>
      <footer className=" page-footer font-small  pt-4">
        <div className="container-fluid   text-light text-md-left">
          <div className="row">
            <div className="col-md-6 mt-md-0 mt-3 ds ">
              <h5 className="text-uppercase ">Finmap Solutions Pvt Ltd</h5>
              <div className={classes.bd}></div>
              <p className="ds">
                A devoted channel to accomplish our organizations vision & our
                allied partner’s mission. Be a part of Finmap Solutions &
                enhance your business model with multiple of digital
                opportunities..
              </p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0" />

            <div className="col-md-3 mb-md-0 mb-3 ds">
              <h5 className="text-uppercase">Quick Links</h5>
              <div className={classes.bd}></div>
              <ul className="list-unstyled">
                <li className={classes.footerLi}>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className={classes.footerLi}>
                  <NavLink to="about-us">About us</NavLink>
                </li>
                <li className={classes.footerLi}>
                  <NavLink to="our-team">Our Team</NavLink>
                </li>
                <li className={classes.footerLi}>
                  <NavLink to="services">Services</NavLink>
                </li>
                <li className={classes.footerLi}>
                  <NavLink to="/careers">Careers</NavLink>
                </li>
              </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3 ds">
              <h5 className="text-uppercase">Get In Touch</h5>
              <div className={classes.bd}></div>
              <ul className="list-unstyled">
                <li className={classes.fLi}>
                  <span className={classes.footerDivA}>
                    <FontAwesomeIcon icon={faMapMarker} />
                  </span>
                  <p className={classes.footerP}>
                    H.No. 43, Hari Kunj,  Shiboo Chowk, Sukhdeo Nagar,
                    Near Bhawani Shiv Mahabir Mandir, Ratu Rd,  Ranchi, Jharkhand 834005
                  </p>
                </li>
                <li className={classes.fLi}>
                <span className={classes.footerDivA}>
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <p className={classes.footerP}>
                  0651 - 3520744

                  </p>
                </li>
                <li className={classes.fLi}>
                <span className={classes.footerDivA}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <p className={classes.footerP}>
                  operations.fspl@gmail.com

                  </p>
                </li>
              
              </ul>
            </div>
          </div>
        </div>
        <hr style={{color:"white"}}></hr>

        <div className="footer-copyright text-center text-light py-3">
          © 2022 Copyright:
          <a
            className="text-light"
            style={{ textDecoration: "none", margin: "0 0.25rem" }}
            href="#"
          >
            FinmapSolutions.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
