
import slider1 from "../images/Slider/1.jpg";
import slider2 from "../images/Slider/2.jpg";
import slider3 from "../images/Slider/3.jpg";
import slider4 from "../images/Slider/4.jpg";
import slider5 from "../images/Slider/5.jpg";
import slider6 from "../images/Slider/6.jpg";
import classes from "./Gallery.module.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useEffect } from "react";

const Gallery = () => {
  const [windowDimenstion, setWindowDimension] = useState(null);

  useEffect(()=>{
    setWindowDimension(window.innerWidth);
  },[])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 

  var settings = {
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    
  };

  if(windowDimenstion<800){
    settings.slidesToShow = 2
  }

  if(windowDimenstion<600){
    settings.slidesToShow = 1
  }

  const images = [
    slider6,
     slider3,
     slider4,
    slider2,
     slider5,
    slider1
    
  ]



  return (
    <div className={classes.SliderHolder}>
      <h1 className={classes.sectionH1}> Photo Gallery</h1>

      <Slider {...settings}>
     
        {images.map ((i,index) => (
         
            <div className="galleryDiv" key={index}>
              
          <img className="gallery" src={i} alt="Second slide"  key ={index}/>
        </div>
        
        )) }
      </Slider>
    </div>
  );
};
export default Gallery;
