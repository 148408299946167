import classes from "./TopNav.module.css";
import logo from "../utils/images/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {  faPhone,faEnvelope } from "@fortawesome/free-solid-svg-icons";

const TopNav = () => {
  return (
    <section className={classes.box}>
      <nav className={classes.navTopNav}>
        <div className={classes.ParentDivTopNav}>
          <div className={classes.divTopNav}>
            <img className={classes.logoImage}src={logo} alt=" logo Finmark" />
          </div>
          <div className={classes.divTopNavA}> <span className={classes.IconSpan}> <FontAwesomeIcon icon={faPhone}/> </span>     Contact Number :  <br></br>
          0651 - 3520744
          </div>
          <div className={classes.divTopNavA}>  <span className={classes.IconSpan}> <FontAwesomeIcon icon={faEnvelope}/> </span>  Email us : <br></br>
          operations.fspl@gmail.com
          </div>
        </div>
      </nav>
    </section>
  );
};

export default TopNav;
