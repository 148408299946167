import classes from "./Services.module.css";
import Cards from "./helpers/Card";
import kioskBanking from "./images/ServiceDetailImg/KioskBanking.png";

import financialMarket from "./images/financialmarket.jpg";
import domesticMoneyTransfer from "./images/domesticmoneytransfer.png";
import Recharge from "./images/recharge.png";
import panCardAllotment from "./images/pancardallotment.png";
import microAtm from "./images/microatm.png";
import Aeps from "./images/AEPS.png";
import billpayment from "./images/ServiceDetailImg/billpayment.png";
import insurance from "./images/ServiceDetailImg/insurance.png";

const Services = () => {
  return (
    <>
    {/* <svg className={classes.servicesSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,96L48,122.7C96,149,192,203,288,202.7C384,203,480,149,576,154.7C672,160,768,224,864,218.7C960,213,1056,139,1152,122.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg> */}
    {/* <div className="wave spacer">
    
    </div> */}

    <section className="wave spacer">
 
      <div className={classes.servicesDiv}>
     
        <h1 className={classes.hServices}>Services we Offer</h1>
      </div>
      <div className={classes.CoverdivServices}>
        <div className={classes.ServicesCardDiv}>
         
          <Cards title="Banking Kiosk facility, BC enrollment process and benefits"  summary = "Now a day in 21st century also, approximately more than 50% of our population..." image={kioskBanking} id="banking-Kiosk-facility"/>
          <Cards title="Company offers Aadhaar Enabled Payment System (AEPS)" summary = "AEPS is a bank led model which allows online interoperable..." image={Aeps} id="2"/>
          <Cards title="Domestic Money Transfer"  image={domesticMoneyTransfer} summary = "Domestic Money Transfer is a service offered by various companies/agencies to transfer money..." id="3"/>
          <Cards title="Pan Card Allotment"  image={panCardAllotment} summary = "Through portal an application for fresh allotment of PAN can be made. Further, requests for..." id="4"/>
          <Cards title="Micro ATM"  image={microAtm} summary = "Micro ATM meant to be a device that is used by a million Business Correspondents (BC) to  ..." id="5"/>
          <Cards title="Recharges"  image={Recharge} summary = "All types of mobile prepaid/postpaid Online recharge - Fast & easy mobile recharge online at"id="6"/>
          <Cards title="Bill Payment"  summary = "Pay All type of utility Bill online next to your door..." image={billpayment} id="7"/>
          <Cards title="Box Insurance" summary = "All types of insurance through our portal easily by comparing the premiums from different insurance service providers...." image={insurance} id="8"/>
        </div>
      </div>
 
    </section>
    </>
  );
};

export default Services;
