import Slider from "../utils/Sliders/Slider";
import Location from "../utils/Location";
import Gallery from '../utils/Sliders/Gallery'
import Services from "../utils/Services";
import BelowSection from "../utils/BelowSection";
import Career from "../utils/Career";

const Home = () =>{


    return (
      <>
       <Slider/>
       <Services/>
       {/* <Map/> */}
       <hr/>
       <BelowSection/>
       
       <Gallery/>
     
      
       <Career/>
       <hr/>
       <Location/>
       <hr style={{height:"1px",margin:"0"}}/>
  

       </>
);
}

export default Home;